// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-telugu-unit-one-be-js": () => import("./../../../src/pages/telugu/unit-one/be.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-be-js" */),
  "component---src-pages-telugu-unit-one-close-js": () => import("./../../../src/pages/telugu/unit-one/close.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-close-js" */),
  "component---src-pages-telugu-unit-one-come-js": () => import("./../../../src/pages/telugu/unit-one/come.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-come-js" */),
  "component---src-pages-telugu-unit-one-cross-js": () => import("./../../../src/pages/telugu/unit-one/cross.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-cross-js" */),
  "component---src-pages-telugu-unit-one-dance-js": () => import("./../../../src/pages/telugu/unit-one/dance.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-dance-js" */),
  "component---src-pages-telugu-unit-one-do-js": () => import("./../../../src/pages/telugu/unit-one/do.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-do-js" */),
  "component---src-pages-telugu-unit-one-eat-js": () => import("./../../../src/pages/telugu/unit-one/eat.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-eat-js" */),
  "component---src-pages-telugu-unit-one-expect-js": () => import("./../../../src/pages/telugu/unit-one/expect.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-expect-js" */),
  "component---src-pages-telugu-unit-one-finish-js": () => import("./../../../src/pages/telugu/unit-one/finish.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-finish-js" */),
  "component---src-pages-telugu-unit-one-go-js": () => import("./../../../src/pages/telugu/unit-one/go.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-go-js" */),
  "component---src-pages-telugu-unit-one-index-js": () => import("./../../../src/pages/telugu/unit-one/index.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-index-js" */),
  "component---src-pages-telugu-unit-one-joke-js": () => import("./../../../src/pages/telugu/unit-one/joke.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-joke-js" */),
  "component---src-pages-telugu-unit-one-know-js": () => import("./../../../src/pages/telugu/unit-one/know.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-know-js" */),
  "component---src-pages-telugu-unit-one-learn-js": () => import("./../../../src/pages/telugu/unit-one/learn.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-learn-js" */),
  "component---src-pages-telugu-unit-one-like-js": () => import("./../../../src/pages/telugu/unit-one/like.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-like-js" */),
  "component---src-pages-telugu-unit-one-live-js": () => import("./../../../src/pages/telugu/unit-one/live.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-live-js" */),
  "component---src-pages-telugu-unit-one-make-js": () => import("./../../../src/pages/telugu/unit-one/make.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-make-js" */),
  "component---src-pages-telugu-unit-one-meet-js": () => import("./../../../src/pages/telugu/unit-one/meet.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-meet-js" */),
  "component---src-pages-telugu-unit-one-open-js": () => import("./../../../src/pages/telugu/unit-one/open.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-open-js" */),
  "component---src-pages-telugu-unit-one-play-js": () => import("./../../../src/pages/telugu/unit-one/play.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-play-js" */),
  "component---src-pages-telugu-unit-one-speak-js": () => import("./../../../src/pages/telugu/unit-one/speak.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-speak-js" */),
  "component---src-pages-telugu-unit-one-study-js": () => import("./../../../src/pages/telugu/unit-one/study.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-study-js" */),
  "component---src-pages-telugu-unit-one-think-js": () => import("./../../../src/pages/telugu/unit-one/think.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-think-js" */),
  "component---src-pages-telugu-unit-one-throw-js": () => import("./../../../src/pages/telugu/unit-one/throw.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-throw-js" */),
  "component---src-pages-telugu-unit-one-understand-js": () => import("./../../../src/pages/telugu/unit-one/understand.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-understand-js" */),
  "component---src-pages-telugu-unit-one-walk-js": () => import("./../../../src/pages/telugu/unit-one/walk.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-walk-js" */),
  "component---src-pages-telugu-unit-one-want-js": () => import("./../../../src/pages/telugu/unit-one/want.js" /* webpackChunkName: "component---src-pages-telugu-unit-one-want-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

